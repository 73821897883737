import * as React from "react";
import { graphql } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import CallToAction from "../components/Section/CallToAction";
import Impacts, { Impact } from "../components/Section/Impacts";
import Contact from "../components/Section/Contact";
// import Link from "../components/LocalizedLink";

interface IProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    service: {
      data: {
        title: {
          text: string;
        };
        body: {
          text: string;
          html: string;
        };
      };
    };

    impacts: {
      nodes: Impact[];
    };
    home: {
      nodes: Array<any>;
    };
    contact: {
      nodes: Array<any>;
    };
  };
  pageContext: any;
  location: Location;
}
const ServiceTemplate = ({ data, pageContext, location }: IProps) => {
  const service = data.service;
  const siteTitle = data.site.siteMetadata.title;
  const homeData = data.home.nodes[0].data;

  return (
    <Layout location={location} title={siteTitle} pageContext={pageContext}>
      <SEO
        title={service.data.title.text}
        description={service.data.body.text.substr(0, 200)}
      />
      {/* <div
        className="breadcrumb-area pt-230 pb-235"
        style={{ backgroundImage: "url(/images/bg/bg-14.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="breadcrumb-text text-center">
                <h1>{service.data.title.text}</h1>
                <ul className="breadcrumb-menu">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>Service</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="blog-area pt-120 pb-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <article className="postbox post format-image mb-40">
                <div className="postbox__text bg-none">
                  <h3 className="blog-title">{service.data.title.text}</h3>
                  <div
                    className="post-text mb-20"
                    dangerouslySetInnerHTML={{ __html: service.data.body.html }}
                  ></div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <CallToAction data={homeData} />
      <Impacts data={homeData} cases={data.impacts.nodes} />
      <Contact data={data.contact} />
    </Layout>
  );
};

export default ServiceTemplate;

export const pageQuery = graphql`
  query ServiceBySlug($uid: String!, $locale: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    service: prismicService(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        body: full_body {
          text
          html
        }
        title {
          text
        }
      }
    }
    home: allPrismicHomepage(filter: { lang: { eq: $locale } }) {
      nodes {
        data {
          call_to_action {
            title {
              text
            }
            description {
              text
            }
            link {
              url
            }
            button_title {
              text
            }
            phone {
              text
            }
          }
          cases {
            title {
              text
            }
            description {
              text
            }
          }
          testimonials {
            title {
              text
            }
            description {
              text
            }
          }
        }
      }
    }
    impacts: allPrismicCases(filter: { lang: { eq: $locale } }) {
      nodes {
        uid
        data {
          title {
            text
          }
          cover {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    contact: allPrismicForms(filter: { lang: { eq: $locale } }) {
      nodes {
        uid
        data {
          title {
            text
          }
          description {
            text
            html
          }
          backgroundimage {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          buttontitle {
            text
          }
          successmessage {
            text
          }
          errormessage {
            text
          }
          body {
            slice_type
            items {
              label {
                text
              }
              field_name {
                text
              }
              type
              placeholder {
                text
              }
            }
          }
        }
      }
    }
  }
`;
